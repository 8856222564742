<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="currentUser && Object.keys(currentUser).length !== 0"
      :account="currentUser"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4 v-if="currentUser !== null">
            {{ $t('page.payments.balance') }}: {{ currencyFormatter(currentUser.currency, currentUser.balance) }}
          </h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
        <div class="head-card-bottom">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{name: 'dashboard-users-payments-methods'}"
          >
            <feather-icon
              icon="ArrowDownIcon"
              size="16"
            />
            {{ $t('page.payments.replenish') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{name: 'dashboard-users-transaction'}"
          >
            <feather-icon
              icon="CreditCardIcon"
              size="16"
            />
            {{ $t('page.user.payments') }}
          </b-button>
        </div>
      </div>
      <hr>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length"
        class="users-table mt-2"
      >
        <!-- table -->
        <vue-good-table
          v-if="totalRecords > 0"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{enabled: true}"
          :total-rows="totalRecords"
          style-class="vgt-table striped"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Date'"
              class="text-nowrap"
            >
              {{ $t('page.payments.date') }}
            </span>
            <span
              v-else-if="props.column.label ==='Type'"
              class="text-nowrap"
            >
              {{ $t('page.payments.type') }}
            </span>
            <span
              v-else-if="props.column.label ==='Sum'"
              class="text-nowrap"
            >
              {{ $t('page.payments.sum') }}
            </span>
            <span
              v-else-if="props.column.label ==='Source'"
              class="text-nowrap"
            >
              {{ $t('page.payments.source') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'payment_id'"
              class="text-nowrap"
            >
              #{{ props.row.payment_id }}
            </span>
            <span
              v-if="props.column.field === 'date'"
              class="text-nowrap"
            >
              {{ getFormattedDate(new Date(props.row.date), true) }}
            </span>

            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              <b-badge
                class="payment-status"
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status }}
              </b-badge>
            </span>

            <span
              v-if="props.column.field === 'amount' && currentUser !== null"
              class="text-nowrap"
            >
              <strong>{{ '+' }} {{ currencyFormatter(currentUser.currency, props.row.amount) }}</strong>
            </span>
            <span
              v-if="props.column.field === 'provider'"
              class="text-nowrap"
            >
              <svg
                v-if="props.row.provider === 'paypal'"
                xmlns="http://www.w3.org/2000/svg"
                width="57px"
                height="18px"
                viewBox="0 0 101 32"
                fill="#003087"
              >
                <path d="M 12.237 2.8 L 4.437 2.8 C 3.937 2.8 3.437 3.2 3.337 3.7 L 0.237 23.7 C 0.137 24.1 0.437 24.4 0.837 24.4 L 4.537 24.4 C 5.037 24.4 5.537 24 5.637 23.5 L 6.437 18.1 C 6.537 17.6 6.937 17.2 7.537 17.2 L 10.037 17.2 C 15.137 17.2 18.137 14.7 18.937 9.8 C 19.237 7.7 18.937 6 17.937 4.8 C 16.837 3.5 14.837 2.8 12.237 2.8 Z M 13.137 10.1 C 12.737 12.9 10.537 12.9 8.537 12.9 L 7.337 12.9 L 8.137 7.7 C 8.137 7.4 8.437 7.2 8.737 7.2 L 9.237 7.2 C 10.637 7.2 11.937 7.2 12.637 8 C 13.137 8.4 13.337 9.1 13.137 10.1 Z" />
                <path d="M 35.437 10 L 31.737 10 C 31.437 10 31.137 10.2 31.137 10.5 L 30.937 11.5 L 30.637 11.1 C 29.837 9.9 28.037 9.5 26.237 9.5 C 22.137 9.5 18.637 12.6 17.937 17 C 17.537 19.2 18.037 21.3 19.337 22.7 C 20.437 24 22.137 24.6 24.037 24.6 C 27.337 24.6 29.237 22.5 29.237 22.5 L 29.037 23.5 C 28.937 23.9 29.237 24.3 29.637 24.3 L 33.037 24.3 C 33.537 24.3 34.037 23.9 34.137 23.4 L 36.137 10.6 C 36.237 10.4 35.837 10 35.437 10 Z M 30.337 17.2 C 29.937 19.3 28.337 20.8 26.137 20.8 C 25.037 20.8 24.237 20.5 23.637 19.8 C 23.037 19.1 22.837 18.2 23.037 17.2 C 23.337 15.1 25.137 13.6 27.237 13.6 C 28.337 13.6 29.137 14 29.737 14.6 C 30.237 15.3 30.437 16.2 30.337 17.2 Z" />
                <path d="M 55.337 10 L 51.637 10 C 51.237 10 50.937 10.2 50.737 10.5 L 45.537 18.1 L 43.337 10.8 C 43.237 10.3 42.737 10 42.337 10 L 38.637 10 C 38.237 10 37.837 10.4 38.037 10.9 L 42.137 23 L 38.237 28.4 C 37.937 28.8 38.237 29.4 38.737 29.4 L 42.437 29.4 C 42.837 29.4 43.137 29.2 43.337 28.9 L 55.837 10.9 C 56.137 10.6 55.837 10 55.337 10 Z" />
                <path d="M 67.737 2.8 L 59.937 2.8 C 59.437 2.8 58.937 3.2 58.837 3.7 L 55.737 23.6 C 55.637 24 55.937 24.3 56.337 24.3 L 60.337 24.3 C 60.737 24.3 61.037 24 61.037 23.7 L 61.937 18 C 62.037 17.5 62.437 17.1 63.037 17.1 L 65.537 17.1 C 70.637 17.1 73.637 14.6 74.437 9.7 C 74.737 7.6 74.437 5.9 73.437 4.7 C 72.237 3.5 70.337 2.8 67.737 2.8 Z M 68.637 10.1 C 68.237 12.9 66.037 12.9 64.037 12.9 L 62.837 12.9 L 63.637 7.7 C 63.637 7.4 63.937 7.2 64.237 7.2 L 64.737 7.2 C 66.137 7.2 67.437 7.2 68.137 8 C 68.637 8.4 68.737 9.1 68.637 10.1 Z" />
                <path d="M 90.937 10 L 87.237 10 C 86.937 10 86.637 10.2 86.637 10.5 L 86.437 11.5 L 86.137 11.1 C 85.337 9.9 83.537 9.5 81.737 9.5 C 77.637 9.5 74.137 12.6 73.437 17 C 73.037 19.2 73.537 21.3 74.837 22.7 C 75.937 24 77.637 24.6 79.537 24.6 C 82.837 24.6 84.737 22.5 84.737 22.5 L 84.537 23.5 C 84.437 23.9 84.737 24.3 85.137 24.3 L 88.537 24.3 C 89.037 24.3 89.537 23.9 89.637 23.4 L 91.637 10.6 C 91.637 10.4 91.337 10 90.937 10 Z M 85.737 17.2 C 85.337 19.3 83.737 20.8 81.537 20.8 C 80.437 20.8 79.637 20.5 79.037 19.8 C 78.437 19.1 78.237 18.2 78.437 17.2 C 78.737 15.1 80.537 13.6 82.637 13.6 C 83.737 13.6 84.537 14 85.137 14.6 C 85.737 15.3 85.937 16.2 85.737 17.2 Z" />
                <path d="M 95.337 3.3 L 92.137 23.6 C 92.037 24 92.337 24.3 92.737 24.3 L 95.937 24.3 C 96.437 24.3 96.937 23.9 97.037 23.4 L 100.237 3.5 C 100.337 3.1 100.037 2.8 99.637 2.8 L 96.037 2.8 C 95.637 2.8 95.437 3 95.337 3.3 Z" />
              </svg>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="serverParams.size"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="serverParams.page"
                  :total-rows="props.total"
                  :per-page="serverParams.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div
        v-else
        class="mt-1"
      >
        <div
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert alert-secondary"
        >
          <div class="alert-body text-center">
            <span>{{ $t('page.payments.nopayments') }}</span>
          </div>
        </div>
      </div>
    </b-card>
  </div>

</template>

<script>
import { getUserData } from '@/auth/utils'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate from '@/utils/date-formatter'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BSpinner, BPagination, BFormSelect, BButton, VBTooltip, BBadge,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    Breadcrumbs,
    BSpinner,
    BCard,
    BBadge,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      breadcrumbItems: [],
      getFormattedDate,
      currencyFormatter,
      loading: true,
      currentUser: {},
      pageLength: 20,
      dir: false,
      serverParams: {
        page: 1,
        size: 20,
      },
      totalRecords: 0,
      columns: [
        {
          label: 'Payment id',
          field: 'payment_id',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Source',
          field: 'provider',
          sortable: false,
        },
        {
          label: 'Sum',
          field: 'amount',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
      ],
      rows: [],
      payAmount: 0,
      changeAmount: 0,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        completed    : 'light-success',
        faild        : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        pending      : 'lighnsecondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    serverParams: {
      handler() {
        this.fetchUserTransaction()
      },
      deep: true,
    },
  },
  created() {
    this.fetchUserTransaction()
  },
  methods: {
    async fetchUserTransaction() {
      this.loading = true
      const { userId } = this.$route.params
      const params = this.filterServerParams()
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.page) this.serverParams.page = urlParams.page
      if (urlParams.size) this.serverParams.size = urlParams.size

      await useJwt.getUserTransaction(userId, { page: params.page, limit: params.size }).then(response => {
        this.totalRecords = response.data.count
        this.rows = response.data.data
      }).finally(() => {
        this.loading = false
      })
      this.fetchUser(userId)
    },
    filterServerParams() {
      const filtredParams = {}
      Object.keys(this.serverParams).forEach(p => {
        if (this.serverParams[p] !== '') {
          filtredParams[p] = this.serverParams[p]
        }
      })
      return filtredParams
    },
    async fetchUser(userId) {
      const responseData = await useJwt.getUser(userId)
      this.currentUser = responseData.data.account || []
      this.setBreabcrumbs()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.currentUser.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.user.payments'),
          active: true,
        },
      ]
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage
      this.$router.replace({
        name: 'dashboard-users-transaction',
        query: this.filterServerParams(),
      }).catch(() => {})
    },
    onSortChange(params) {
      this.serverParams.sortField = params[0].field
      this.serverParams.sortOrder = params[0].type
    },
    onPerPageChange(params) {
      this.serverParams.size = params.currentPerPage
      this.serverParams.page = 1
      this.$router.replace({
        name: 'dashboard-users-transaction',
        query: this.filterServerParams(),
      }).catch(() => {})
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style scoped>
.payment-status {
  font-size: 13px;
}
</style>
